<template>
  <div class="contents register funding review" v-loading="loading">
    <div class="title flexB">
      <h2>리뷰 상세</h2>
    </div>
    <div class="box one">
      <!-- <div class="flexL">
        <p class="bold">
          카테고리
          <span>*</span>
        </p>
        <span>
          {{
            category == "SPECIAL"
              ? "스페셜"
              : category == "TECH"
              ? "테크/가전"
              : category == "FASHION"
              ? "패션/잡화"
              : category == "BEAUTY"
              ? "뷰티"
              : category == "FOOD"
              ? "푸드"
              : category == "HOMELIVING"
              ? "홈리빙"
              : category == "TRIP"
              ? "여행/레저"
              : category == "SPORTS"
              ? "스포츠/모빌리티"
              : category == "CULTURE"
              ? "공연/컬쳐"
              : category == "KIDS"
              ? "베이비/키즈"
              : ""
          }}</span
        >
      </div> -->
      <div class="flexL">
        <p class="bold">설문명</p>
        <span
          ><router-link :to="`/survey/detail/${postId}`">{{ title }}</router-link></span
        >
      </div>

      <div class="flexL">
        <p class="bold">이메일</p>
        <span>{{ email }}</span>
      </div>
      <div class="flexL">
        <p class="bold">이름</p>
        <span>{{ username }}</span>
      </div>
      <div class="flexL">
        <p class="bold">내용</p>
        <pre>{{ comment }}</pre>
      </div>

      <div class="flexL">
        <p class="bold">작성일</p>
        <div class="flexL">
          <span>{{ moment(createdAt).format("YYYY-MM-DD") }}</span>
        </div>
      </div>
      <div class="buttonWrap">
        <button class="basic" v-if="isBlind" @click="handleBlind">블라인드 해제</button>
        <button class="basic" v-else @click="handleBlind">블라인드</button>
        <router-link to="/admin/reviews">
          <button class="right line">목록</button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchSurvey, createBlind } from "@/api/admin";
import moment from "moment";
import { format } from "@/mixins/format";
export default {
  name: "reviewDetail",
  mixins: [format],

  data() {
    return {
      moment: moment,
      email: "",
      createdAt: "",
      title: "",
      comment: "",
      username: "",
      postId: "",
      surveyId: "",
      isBlind: false,
      loading: false,
    };
  },

  mounted() {
    this.loading = true;

    if (this.$route.params.id) {
      this.surveyId = this.$route.params.id;
      this.getSurveyDetail();
    }
  },
  methods: {
    getSurveyDetail() {
      fetchSurvey(this.surveyId).then((res) => {
        if (res.data.status == 200) {
          this.postId = res.data.data.post._id;
          this.surveyId = res.data.data._id;
          this.createdAt = res.data.data.createdAt;
          this.title = res.data.data.post.title;
          this.comment = res.data.data.comment;
          this.email = res.data.data.user.email;
          this.username = res.data.data.user.username;
          this.isBlind = res.data.data.isBlind;
          this.loading = false;
        } else {
          return alert(res.data.message);
        }
      });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getPost();
    },
    handleBlind() {
      if (this.isBlind == false) {
        const result = confirm("리뷰를 블라인드 처리 하시겠습니까?");
        if (result == false) {
          return;
        }
      } else {
        const result = confirm("리뷰를 블라인드 해제 하시겠습니까?");
        if (result == false) {
          return;
        }
      }
      let data = {
        surveyId: this.surveyId,
        isBlind: this.isBlind ? false : true,
      };
      createBlind(data).then((res) => {
        if (res.data.status == 200) {
          this.getSurveyDetail();
        } else {
          alert(res.data.message);
        }
      });
    },
    // handleDelete() {
    //   removePost(this.surveyId).then((res) => {
    //     if (res.data.status == 200) {
    //       this.$notify({
    //         title: "Success",
    //         message: "설문이 삭제 되었습니다.",
    //         type: "success",
    //       });
    //       this.$router.push("/admin/posts");
    //     } else {
    //       alert(res.data.message);
    //     }
    //   });
    // },
  },
};
</script>
